import { UserRoleEnum } from "contract/enum";

export enum PageEnum {
  budget = "budget",
  themes = "themes",
  timeline = "timeline",
  addWeddingDate = "addWeddingDate",
  vendor = "vendor",
  myEnquiries = "myEnquiries",
  events = "events",
}

export enum SectionEnum {
  userHeader = "userHeader",
  vendorHeader = "vendorHeader",
  commonHeader = "commonHeader",
  userProjectDrawer = "userProjectDrawer",
  proPlannerProjectDrawer = "proPlannerProjectDrawer",
}

export enum ExtendedUserRoleEnum {
  notLoggedIn = "notLoggedIn",
  vendor = "vendor",
  proPlannerVendor = "proPlannerVendor",
  eventsUser = "eventsUser",
}
export enum ActionEnum {
  view = "view",
  add = "add",
  createOrEdit = "createOrEdit",
  delete = "delete",
  invite = "invite",
  dismissableBanner = "dismissableBanner",
  reset = "reset",
  search = "search",
}

const rolePermissions: RolePermissions = {
  [UserRoleEnum.proPlanner]: {
    [SectionEnum.userHeader]: [ActionEnum.view],
    [SectionEnum.vendorHeader]: [],
    [SectionEnum.commonHeader]: [ActionEnum.delete],
    [SectionEnum.userProjectDrawer]: [],
    [SectionEnum.proPlannerProjectDrawer]: [ActionEnum.view],
    [PageEnum.budget]: [
      ActionEnum.view,
      ActionEnum.createOrEdit,
      ActionEnum.delete,
    ],
    [PageEnum.themes]: [
      ActionEnum.view,
      ActionEnum.createOrEdit,
      ActionEnum.delete,
      ActionEnum.add,
      ActionEnum.search,
    ],
    [PageEnum.timeline]: [
      ActionEnum.view,
      ActionEnum.createOrEdit,
      ActionEnum.add,
      ActionEnum.delete,
      ActionEnum.invite,
      ActionEnum.reset,
    ],
    [PageEnum.addWeddingDate]: [
      ActionEnum.view,
      ActionEnum.createOrEdit,
      ActionEnum.add,
    ],
    [PageEnum.vendor]: [],
    [PageEnum.myEnquiries]: [ActionEnum.view],
    [PageEnum.events]: [],
  },
  [UserRoleEnum.spouse]: {
    [SectionEnum.userHeader]: [ActionEnum.view],
    [SectionEnum.vendorHeader]: [],
    [SectionEnum.commonHeader]: [ActionEnum.delete],
    [SectionEnum.userProjectDrawer]: [ActionEnum.view],
    [SectionEnum.proPlannerProjectDrawer]: [],
    [PageEnum.budget]: [
      ActionEnum.view,
      ActionEnum.createOrEdit,
      ActionEnum.delete,
    ],
    [PageEnum.themes]: [
      ActionEnum.view,
      ActionEnum.createOrEdit,
      ActionEnum.delete,
      ActionEnum.add,
      ActionEnum.search,
    ],
    [PageEnum.timeline]: [
      ActionEnum.view,
      ActionEnum.createOrEdit,
      ActionEnum.add,
      ActionEnum.delete,
      ActionEnum.invite,
      ActionEnum.reset,
    ],
    [PageEnum.addWeddingDate]: [
      ActionEnum.view,
      ActionEnum.createOrEdit,
      ActionEnum.add,
    ],
    [PageEnum.vendor]: [],
    [PageEnum.myEnquiries]: [ActionEnum.view],
    [PageEnum.events]: [ActionEnum.view],
  },
  [ExtendedUserRoleEnum.eventsUser]: {
    [SectionEnum.userHeader]: [ActionEnum.view],
    [SectionEnum.vendorHeader]: [],
    [SectionEnum.commonHeader]: [ActionEnum.delete],
    [SectionEnum.userProjectDrawer]: [ActionEnum.view],
    [SectionEnum.proPlannerProjectDrawer]: [ActionEnum.view],
    [PageEnum.budget]: [],
    [PageEnum.themes]: [],
    [PageEnum.timeline]: [],
    [PageEnum.addWeddingDate]: [
      ActionEnum.view,
      ActionEnum.createOrEdit,
      ActionEnum.add,
    ],
    [PageEnum.vendor]: [],
    [PageEnum.myEnquiries]: [],
    [PageEnum.events]: [ActionEnum.view],
  },
  [UserRoleEnum.planner]: {
    [SectionEnum.userHeader]: [ActionEnum.view],
    [SectionEnum.vendorHeader]: [],
    [SectionEnum.commonHeader]: [ActionEnum.delete],
    [SectionEnum.userProjectDrawer]: [ActionEnum.view],
    [SectionEnum.proPlannerProjectDrawer]: [],
    [PageEnum.budget]: [ActionEnum.view, ActionEnum.createOrEdit],
    [PageEnum.themes]: [
      ActionEnum.view,
      ActionEnum.dismissableBanner,
      ActionEnum.search,
    ],
    [PageEnum.timeline]: [ActionEnum.view, ActionEnum.createOrEdit],
    [PageEnum.addWeddingDate]: [],
    [PageEnum.vendor]: [],
    [PageEnum.myEnquiries]: [ActionEnum.view],
    [PageEnum.events]: [ActionEnum.view],
  },
  [UserRoleEnum.collaborator]: {
    [SectionEnum.userHeader]: [ActionEnum.view],
    [SectionEnum.vendorHeader]: [],
    [SectionEnum.commonHeader]: [ActionEnum.delete],
    [SectionEnum.userProjectDrawer]: [ActionEnum.view],
    [SectionEnum.proPlannerProjectDrawer]: [],
    [PageEnum.budget]: [],
    [PageEnum.themes]: [],
    [PageEnum.timeline]: [ActionEnum.view, ActionEnum.dismissableBanner],
    [PageEnum.addWeddingDate]: [],
    [PageEnum.vendor]: [],
    [PageEnum.myEnquiries]: [ActionEnum.view],
    [PageEnum.events]: [ActionEnum.view],
  },
  [ExtendedUserRoleEnum.vendor]: {
    [SectionEnum.userHeader]: [],
    [SectionEnum.vendorHeader]: [ActionEnum.view],
    [SectionEnum.commonHeader]: [ActionEnum.delete],
    [SectionEnum.userProjectDrawer]: [],
    [SectionEnum.proPlannerProjectDrawer]: [],
    [PageEnum.budget]: [],
    [PageEnum.themes]: [],
    [PageEnum.timeline]: [],
    [PageEnum.addWeddingDate]: [],
    [PageEnum.vendor]: [
      ActionEnum.view,
      ActionEnum.createOrEdit,
      ActionEnum.delete,
      ActionEnum.add,
    ],
    [PageEnum.myEnquiries]: [],
    [PageEnum.events]: [],
  },
  [ExtendedUserRoleEnum.proPlannerVendor]: {
    [SectionEnum.userHeader]: [],
    [SectionEnum.vendorHeader]: [ActionEnum.view],
    [SectionEnum.commonHeader]: [ActionEnum.delete],
    [SectionEnum.userProjectDrawer]: [],
    [SectionEnum.proPlannerProjectDrawer]: [ActionEnum.view],
    [PageEnum.budget]: [],
    [PageEnum.themes]: [],
    [PageEnum.timeline]: [],
    [PageEnum.addWeddingDate]: [],
    [PageEnum.vendor]: [
      ActionEnum.view,
      ActionEnum.createOrEdit,
      ActionEnum.delete,
      ActionEnum.add,
    ],
    [PageEnum.myEnquiries]: [],
    [PageEnum.events]: [],
  },
  [ExtendedUserRoleEnum.notLoggedIn]: {
    [SectionEnum.userHeader]: [ActionEnum.view],
    [SectionEnum.vendorHeader]: [],
    [SectionEnum.commonHeader]: [],
    [SectionEnum.userProjectDrawer]: [],
    [SectionEnum.proPlannerProjectDrawer]: [],
    [PageEnum.budget]: [ActionEnum.view],
    [PageEnum.themes]: [ActionEnum.view, ActionEnum.search],
    [PageEnum.timeline]: [ActionEnum.view],
    [PageEnum.addWeddingDate]: [],
    [PageEnum.vendor]: [],
    [PageEnum.myEnquiries]: [],
    [PageEnum.events]: [],
  },
};

type RolePermissions = {
  [key in UserRoleEnum | ExtendedUserRoleEnum]: {
    [key in PageEnum | SectionEnum]: ActionEnum[];
  };
};

export const canPerformAction = ({
  role,
  pageOrSection,
  action,
}: {
  role: UserRoleEnum | ExtendedUserRoleEnum | null;
  pageOrSection: PageEnum | SectionEnum;
  action: ActionEnum;
}) => {
  const passedRole = role || ExtendedUserRoleEnum.notLoggedIn;
  const permissions = rolePermissions[passedRole][pageOrSection];
  return permissions.includes(action);
};
