import { ToastStatus } from "@/hooks/useToast";
import { TimelinePageViewEnum } from "@/types/timeline";
import get from "lodash/get";
import { Area } from "react-easy-crop";
import dayjs from "dayjs";
import { ManageListingActionTypes } from "@/features/vendor/types/vendor";
import { UserDataType } from "@/types/auth";
import { ExtendedUserRoleEnum } from "@/data/pageRestriction";
import {
  EventOrWeddingPathChosen,
  ProPlannerView,
  UserRoleEnum,
  VendorTypeEnum,
} from "contract/enum";
import isNil from "lodash/isNil";

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
    return image;
  });

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export default async function getCroppedImg(
  imageSrc: string,
  pixelCrop: Area,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
): Promise<Blob | null> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement("canvas");

  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) {
    return null;
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As Base64 string
  // return croppedCanvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((file) => {
      if (file) {
        resolve(file);
      }
    }, "image/jpeg");
  });
}

export const getNormalizedFileName = (fileName: string) => {
  const arrayOfFile = fileName.split(".");
  return `${arrayOfFile[0]}.${arrayOfFile[arrayOfFile.length - 1]}`;
};

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const percentageToValue = (percent: number, total: number) => {
  return (percent * total) / 100;
};

export const comingSoonToast = {
  status: ToastStatus.success,
  id: "comingSoonToastId",
  message: "Coming soon. Stay tuned.",
};

export const resetPasswordText = "Enter your current email to reset password";

export const passwordMatch = "Password matched";

export const passwordDontMatch = "Passwords don't match";

export const requiredErrorMessage = (label: string) => `${label} is required`;

export const getInitialsForAvatar = (name: string) => {
  return name
    .split(" ")
    .slice(0, 2)
    .map((word) => get(word, 0, "").toUpperCase())
    .join("");
};
export const getHomePageUrl = ({
  isLoggedIn,
  userDetails,
}: {
  isLoggedIn: boolean;
  userDetails: UserDataType | null;
}) => {
  if (!isLoggedIn || isNil(userDetails)) {
    return "/";
  }

  const {
    vendorType,
    currentProject,
    proPlannerView,
    eventOrWeddingPathChosen,
  } = userDetails;

  if (
    !isNil(eventOrWeddingPathChosen) &&
    eventOrWeddingPathChosen === EventOrWeddingPathChosen.eventPath
  ) {
    return "/events/manage";
  }
  if (vendorType === VendorTypeEnum.Vendor) {
    return `/manage-listing/?actionType="${ManageListingActionTypes.view}"`;
  }

  if (!vendorType && !isNil(currentProject.weddingDate)) {
    return `/timeline/?timelinePageViewEnum="${TimelinePageViewEnum.gridView}"`;
  }

  if (vendorType === VendorTypeEnum.ProPlanner) {
    if (
      proPlannerView === ProPlannerView.userSide &&
      !isNil(currentProject.weddingDate)
    ) {
      return `/timeline/?timelinePageViewEnum="${TimelinePageViewEnum.gridView}"`;
    }
    return `/manage-listing/?actionType="${ManageListingActionTypes.view}"`;
  }

  return "/timeline";
};
export const formatBlogDate = (date: Date): string => {
  return dayjs(date).format("MMMM D, YYYY");
};

export const extractBlogCategoriesFromId = (
  blogCategories: string
): string[] => {
  return blogCategories
    .split("-")
    .map((category) => category.replaceAll("_", " "));
};

export const constructBlogCategoriesId = (blogCategories: string[]): string => {
  return blogCategories
    .map((category) => category.replaceAll(" ", "_"))
    .join("-");
};
export const removeTrailingZero = (label: string) => {
  if (label.startsWith("0")) {
    return label.slice(1);
  }
  return label;
};

export const getRoleFromUserDetails = (userDetails: UserDataType) => {
  if (
    !isNil(userDetails.eventOrWeddingPathChosen) &&
    userDetails.eventOrWeddingPathChosen === EventOrWeddingPathChosen.eventPath
  ) {
    return ExtendedUserRoleEnum.eventsUser;
  }
  if (userDetails.vendorType === VendorTypeEnum.ProPlanner) {
    if (
      !isNil(userDetails.currentProject.id) &&
      userDetails.proPlannerView === ProPlannerView.userSide
    ) {
      return UserRoleEnum.proPlanner;
    }
    return ExtendedUserRoleEnum.proPlannerVendor;
  }
  if (userDetails.vendorType === VendorTypeEnum.Vendor) {
    return ExtendedUserRoleEnum.vendor;
  }

  return userDetails.currentProjectRole;
};

export const maxPricingValueInDollars = 999999;

export const extractPhoneCodeAndCountry = (phoneCode: string | null) => {
  if (!phoneCode) {
    return { code: null, country: null };
  }

  const [code, country] = phoneCode.split("_");
  return { code, country };
};
